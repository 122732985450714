<!--
 * ======================================
 * 说明： 任务发布
 * 作者： Silence
 * 文件： taskpublish.vue
 * 日期： 2023/9/3 22:49
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :hasAction="true" :options="handleOptions" :loadData="handleLoadData" title="任务管理" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" label-width="80px">
            <el-form-item label="任务名称">
              <el-input v-model="formSearch.title"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="handleLoad" type="primary">查询</el-button>
              <el-button @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <el-button type="primary" icon="el-icon-plus" @click="handleCreate">发布任务</el-button>
        </template>
        <template #table-item-grade="{row}">
          {{ gradeOptions.find(v => v.value === row.grade).label }}
        </template>
        <template #table-item-status="{row}">
          <el-tag v-if="row.status === '已发布'" type="success">已发布</el-tag>
          <el-tag v-else-if="row.status === '已关闭'" type="danger">已关闭</el-tag>
          <el-tag v-else type="primary">待发布</el-tag>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" v-if="row.lock !== 1" icon="el-icon-edit" @click="handleUpdate(row)">编辑</el-button>
          <el-button type="text" v-if="row.lock === 1" class="mt-2" @click="handleUnLock(row)">解锁</el-button>
          <el-button type="text" v-else class="mt-2" @click="handleLock(row)">锁定</el-button>
          <el-button type="text" v-if="row.lock !== 1" icon="el-icon-edit" @click="handleRemove(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script>
import Edit from './components/taskEdit.vue'
import { grade } from '@/utils/global.js'
export default {
  data() {
    return {
      gradeOptions: grade,
      subject_name: '',
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        title:'',
      },
    }
  },
  mounted() {
    this.handleLoad();
  },
  methods: {
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teaching/task").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("task/list", {size, page,...this.formSearch}).then(res => {
        console.log('数组：',res)
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoad() {
      this.$refs['table'].handleLoadData()
    },
    // 添加任务
    handleCreate() {
      this.$cloud.dialog({
        title: '发布任务',
        data: {modal:true},
        component: Edit,
        success: '发布成功!',
        warning: '发布失败!',
        refresh: this.handleRefresh,
        customClass: 'task-publish-custom-class'
      })
    },
    // 修改任务
    handleUpdate(row) {
      console.log("编辑",row)
      this.$cloud.dialog({
        title: '更新任务',
        data: {modal:true,...row},
        component: Edit,
        success: '更新成功!',
        warning: '更新失败!',
        refresh: this.handleRefresh,
        customClass: 'task-publish-custom-class'
      })
    },
    // 删除任务
    handleDelete() {
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    handleReset() {
      this.formSearch = {
        title:'',
      }
      this.$refs['table'].handleLoadData()
    },
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
    // 删除
    handleRemove(row){
      console.log('删除',row)
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$cloud.post("task/remove", {id: row.id}).then(() => {
          this.$message.success('删除成功')
          this.handleRefresh()
        })
      })
    },
    // 锁定
    handleLock(row){
      console.log('锁定',row)
      this.$confirm('确定要锁定吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$cloud.post("task/lock", {id: row.id}).then(() => {
          this.$message.success('锁定成功')
          this.handleRefresh()
        })
      })
    },

    //解锁
    handleUnLock(row){
      console.log('解锁',row)
      this.$prompt('请输入登录密码进行解锁', '解锁', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'password',
        inputPlaceholder: '请输入密码',
        inputErrorMessage: '输入不能为空',
        inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
          if ( !value ) {
            return '输入不能为空';
          }
        }
      }).then(({value}) => {
        this.$cloud.post("task/unlock", {id: row.id,password: value}).then(() => {
          this.$message.success('解锁成功')
          this.handleRefresh()
        })
      })
    },
  },
}
</script>
<style lang="scss">
.task-publish-custom-class{
  width: 960px;
}
</style>
