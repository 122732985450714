<!--
 * ======================================
 * 说明： 任务发布
 * 作者： Silence
 * 文件： taskpublishEdit.vue
 * 日期： 2023/9/3 22:31
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar v-if="modal">
      <Title title="任务发布"/>
      <div class="m-4">
        <el-form :inline="true" ref="form" :model="formInline" :rules="rules" class="demo-form-inline">
          <el-form-item label="选择学期" prop="semester_id">
            <semesterSelect v-model="formInline.semester_id"/>
          </el-form-item>
          <el-form-item label="选择年级" prop="grade">
            <el-select class="w-full" v-model="formInline.grade">
              <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLoadTaskInfo">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="m-2">
        <el-form ref="form" :model="formData" label-width="80px">
          <div class="task-setting-box">
            <el-form-item label="任务名称">
              <el-input v-model="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="任务时间">
              <el-date-picker
                v-model="formData.datetime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-tag v-if="formData.status === 1" type="success">已发布</el-tag>
              <el-tag v-else-if="formData.status === 2" type="danger">已关闭</el-tag>
              <el-tag v-else type="primary">待发布</el-tag>
            </el-form-item>
            <el-form-item label="任务模块">
              <el-cascader-panel :props="props" v-model="formData.content" :options="options"></el-cascader-panel>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button @click="handleTaskSubmit(0)">保存任务</el-button>
            <el-button type="primary" @click="handleTaskSubmit(1)">发布任务</el-button>
            <el-button type="success" @click="handleTaskSubmit(2)">关闭任务</el-button>
          </el-form-item>
        </el-form>
      </div>
    </Scrollbar>
    <template v-else>
      <Title title="任务发布"/>
      <div class="m-4">
        <el-form :inline="true" ref="form" :model="formInline" :rules="rules" class="demo-form-inline">
          <el-form-item label="选择学期" prop="semester_id">
            <semesterSelect v-model="formInline.semester_id"/>
          </el-form-item>
          <el-form-item label="选择年级" prop="grade">
            <el-select class="w-full" v-model="formInline.grade">
              <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLoadTaskInfo">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="m-2">
        <el-form ref="form" :model="formData" label-width="80px">
          <div class="task-setting-box">
            <el-form-item label="任务名称">
              <el-input v-model="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="任务时间">
              <el-date-picker
                  v-model="formData.datetime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-tag v-if="formData.status === 1" type="success">已发布</el-tag>
              <el-tag v-else-if="formData.status === 2" type="danger">已关闭</el-tag>
              <el-tag v-else type="primary">待发布</el-tag>
            </el-form-item>
            <el-form-item label="任务模块">
              <el-cascader-panel :props="props" v-model="formData.content" :options="options"></el-cascader-panel>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button @click="handleTaskSubmit(0)">保存任务</el-button>
            <el-button type="primary" @click="handleTaskSubmit(1)">发布任务</el-button>
            <el-button type="success" @click="handleTaskSubmit(2)">关闭任务</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </div>
</template>
<script>
import options from "@/pages/web/options.js";
import semesterSelect from "@/pages/web/components/semesterSelect.vue";
import { grade } from '@/utils/global.js'
import Scrollbar from "@/theme/school/components/Content/index.vue";
export default {
  components: {
    Scrollbar,
    semesterSelect
  },
  props: {
    value:  {
      type:Object,
      default:null
    },
    modal:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      title: '发布劳动任务',
      semesterOptions: [],
      ddd: [],
      props: {
        multiple: true,
      },
      gradeOptions: grade,
      formInline: {
        semester_id: '',
        grade:'',
      },
      formData: {
        title: '',
        content: [],
        datetime: '',
        status: 0
      },
      options: options,
      rules: {
        semester_id: [
          { required: true, message: '请选择学期', trigger: 'change' }
        ],
        grade: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ]
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    // formInline: {
    //   deep: true,
    //   handler() {
    //       this.handleLoadTaskInfo();
    //   }
    // }
  },
  async mounted() {
    // 加载年级
    await this.handleLoadGrade();
    if(this.value?.id){
      this.formInline.grade = this.value.grade
      this.formInline.semester_id = this.value.semester_id
    }
    await this.handleLoadTaskInfo();
    //加载选项
    await this.handleLoadBlackOptions();
  },
  methods: {
    /**
     * 处理界面功能
     */
    handleTaskSubmit(status) {
      let {semester_id , grade} = this.formInline;
      this.formData.semester_id = semester_id;
      this.formData.status = status
      this.formData.grade = grade
      return this.$cloud.post("task/save", this.formData).then(res => {
        switch ( status ) {
          case 0:
            this.$message.success('保存成功')
            break
          case 1:
            this.$message.success('发布成功')
            break
          case 2:
            this.$message.success('关闭成功')
            break
        }
      })
    },
    /**
     * 处理界面功能
     */
    handleLoadBlackOptions() {
      return this.$cloud.get("block/options",).then(res => {
        this.options = res;
      })
    },
    async handleLoadGrade(){
      await this.$cloud.get("grade/get/now").then(res=>{
        this.formInline.grade= res
      })
    },
    handleLoadTaskInfo() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let {semester_id,grade} = this.formInline;
          return this.$cloud.get("task/detail", {
            semester_id: semester_id,
            grade: grade
          }).then(res => {
            if(typeof res === 'object'){
              this.formData = res;
            }else{
              this.formData= {
                title: '',
                content: [],
                datetime: '',
                status: 0
              }
            }
          })
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.task-setting-box{
  max-height: calc(100vh - 390px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
